import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import SmallTitle from '../components/pages/ressources/smallTitle'
import SEO from '../components/seo'
import useRessources from '../hooks/ressources/useRessources'
import { RootState } from '../redux/store'
import { getInternationalizedValue } from '../utils/common'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  container: {
    // marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(3),
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  titleContainer: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
}))

const Ressources: FC = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const data = useRessources()
  const bitTitle = getInternationalizedValue(data, 'big_title', language)

  return (
    <Box className={classes.root}>
      <SEO title="Ressources" />
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h3">{bitTitle}</Typography>
        </Box>

        <Box className={classes.cardsContainer}>
          <Box className={classes.cards}>
            {data.small_title.map(s => (
              <SmallTitle
                key={`small-title-${s.id}`}
                language={language}
                data={s}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Ressources
