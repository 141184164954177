import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import PlayIcon from '@material-ui/icons/PlayArrow'
import { navigate } from 'gatsby'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: '#21B2BB',
      cursor: 'pointer',
      color: 'white',
    },
  },
}))

interface PageLinkProps {
  text: string
  url: string
}

const PageLink: FC<PageLinkProps> = props => {
  const { text, url } = props
  const { container } = useStyles()

  const handleGoTo = () => {
    navigate(url)
  }

  return (
    <Box className={container} borderRadius={4} onClick={handleGoTo}>
      <Box marginRight={1}>
        <PlayIcon />
      </Box>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  )
}

export default PageLink
