import { graphql, useStaticQuery } from 'gatsby'
import { GraphqlFixedImage } from '../../interfaces/graphql'

export interface PageLinkRessources {
  id: string
  link_fr: string
  url: string
  articles: {
    id: string
  }
}

export interface SmallTitleRessources {
  id: string
  small_title_fr: string
  page_link: PageLinkRessources[]
  image: GraphqlFixedImage | null
}

export interface RessourcesData {
  id: string
  big_title_fr: string
  small_title: SmallTitleRessources[]
}

export default (): RessourcesData => {
  const data = useStaticQuery(graphql`
    {
      strapiRessources {
        id
        big_title_fr
        small_title {
          id
          small_title_fr
          page_link {
            id
            link_fr
            url
            articles {
              id
            }
          }
          image {
            childImageSharp {
              id
              fixed(width: 180) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return data.strapiRessources
}
