import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Img from 'gatsby-image'
import React, { FC } from 'react'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { SmallTitleRessources } from '../../../../hooks/ressources/useRessources'
import {
  getArticlePath,
  getInternationalizedValue,
} from '../../../../utils/common'
import PageLink from './pageLink'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      width: 400,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: 290,
    },
    padding: theme.spacing(2),
    // marginRight: theme.spacing(3),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  titleContainer: {
    position: 'relative',
    height: 200,
    backgroundColor: theme.palette.primary.light,
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
  },
  title: {
    position: 'absolute',
    color: 'white',
    margin: theme.spacing(1.5),
    zIndex: 1000,
    width: 220,
  },
  imageContainer: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  // image: {
  //   width: 180,
  // },
  linkContainer: {
    // height: 290,
    // overflow: 'auto',
    marginTop: theme.spacing(1),
  },
}))

interface SmallTitleProps {
  language: string
  data: SmallTitleRessources
}

const SmallTitle: FC<SmallTitleProps> = props => {
  const { language, data } = props

  const smallTitle = getInternationalizedValue(data, 'small_title', language)
  // console.log('!!', data)

  const {
    container,
    titleContainer,
    imageContainer,
    title,
    linkContainer,
  } = useStyles()

  return (
    <Box className={container} boxShadow={12}>
      <Box className={titleContainer} borderRadius={6}>
        <Box className={title}>
          <Typography variant="h5">{smallTitle}</Typography>
        </Box>
        <Box className={imageContainer}>
          {/* <img src={lireImage} alt="ressource image" className={image} /> */}
          {data.image ? (
            <Img
              fixed={data.image.childImageSharp.fixed}
              alt="ressources-image image"
            />
          ) : (
            <>&nbsp;</>
          )}
        </Box>
      </Box>
      <Box className={linkContainer}>
        {/* <PerfectScrollbar> */}
        {data.page_link.map(p => {
          const textLink = getInternationalizedValue(p, 'link', language)
          const articleUrl =
            p.articles && p.articles ? getArticlePath(p.articles.id) : ''
          const url = articleUrl ? articleUrl : `/${p.url}`
          return (
            <PageLink key={`page-link-${p.id}`} text={textLink} url={url} />
          )
        })}
        {/* </PerfectScrollbar> */}
      </Box>
    </Box>
  )
}

export default SmallTitle
